import styled from 'styled-components';
import { Button } from 'react-rainbow-components';
import CheckingModalIcon from '../icons/CheckingModalIcon';

export const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const StyledCheckingModalIcon = styled(CheckingModalIcon)`
    margin: 20px 0 20px;
`;

export const Title = styled.h1`
    font-size: 25px;
    color: ${(props) => props.theme.rainbow.palette.text.label};
    font-family: 'Lato Bold', Arial, sans-serif;
`;

export const Description = styled.p`
    margin: 20px 50px 0;
    font-size: 16px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
`;

export const Footer = styled.div`
    margin: 20px 50px;
    display: flex;
    justify-content: center;
`;

export const StyledButton = styled(Button)`
    margin: 0 10px;
`;
