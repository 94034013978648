import React from 'react';
import { Input, PhoneInput } from 'react-rainbow-components';
import {
    InputField, Row, LinkContainer, LinkText, StyledLink,
} from './styled';

const OwnerFields = () => (
    <>
        <LinkContainer>
            <LinkText>
                Already have an account in EverTransit?
            </LinkText>
            <StyledLink to="/login">
                Login here
            </StyledLink>
        </LinkContainer>
        <Row>
            <InputField
                id="owner-first-name-input"
                name="owner.firstName"
                component={Input}
                label="First Name"
                placeholder="Enter the owner first name"
                labelAlignment="left"
                required
            />
            <InputField
                id="owner-last-name-input"
                name="owner.lastName"
                component={Input}
                label="Last Name"
                placeholder="Enter the owner last name"
                labelAlignment="left"
                required
            />
        </Row>
        <Row>
            <InputField
                id="owner-email-input"
                name="owner.email"
                component={Input}
                label="Email"
                placeholder="Enter the email"
                labelAlignment="left"
                required
            />
            <InputField
                id="owner-phone-number-input"
                name="owner.phoneNumber"
                component={PhoneInput}
                label="Phone"
                placeholder="Enter the phone"
                labelAlignment="left"
                required
            />
        </Row>
        <Row>
            <InputField
                id="password-input"
                name="owner.password"
                component={Input}
                type="password"
                label="Password"
                placeholder="Enter the password"
                labelAlignment="left"
                required
            />
        </Row>
    </>
);

export default OwnerFields;
