import React from 'react';
import PropTypes from 'prop-types';
import {
    Field, isRequired, isEmail, composeValidators,
} from '@rainbow-modules/forms';
import { Input } from 'react-rainbow-components';

const Fields = ({ emailInitialValue }) => (
    <>
        <Field
            name="email"
            component={Input}
            label="Email"
            placeholder="Enter your email address"
            required
            validate={composeValidators(isRequired(), isEmail())}
            className="rainbow-m-bottom_large"
            initialValue={emailInitialValue}
        />
        <Field
            name="password"
            type="password"
            component={Input}
            label="Password"
            placeholder="Enter your password"
            required
            validate={isRequired()}
            className="rainbow-m-bottom_large"
        />
    </>
);

Fields.propTypes = {
    emailInitialValue: PropTypes.string,
};

Fields.defaultProps = {
    emailInitialValue: undefined,
};

export default Fields;
