import { Button } from 'react-rainbow-components';
import { useParams } from 'react-router-dom';
import {
    Background, Logo, Title, Description, Bold, Arrow,
} from './styled';

const { REACT_APP_DISPATCH_URL } = process.env;

const planMap = {
    'pay-as-you-go': 'Pay As You Go',
    base: 'Base',
    business: 'Business',
};

const ThankYou = () => {
    const { plan } = useParams();
    const planLabel = planMap[plan] || plan;

    const goToDispatch = () => {
        window.location.href = REACT_APP_DISPATCH_URL;
    };

    return (
        <Background>
            <Logo />
            <Title>Thank you for choosing us!</Title>
            <Description>
                You have completed your subscription process successfully.
                Now you are ready to start using EverTransit for your business.
                We hope you enjoy the
                {' '}
                <Bold>
                    &quot;
                    {planLabel}
                    &quot;
                </Bold>
                {' '}
                experience.
            </Description>
            <Button variant="brand" onClick={goToDispatch}>
                Start Now
                <Arrow />
            </Button>
        </Background>
    );
};

export default ThankYou;
