import styled from 'styled-components';
import { Check } from '@rainbow-modules/icons';
import { UniversalForm } from '@rainbow-modules/forms';
import { BACKGROUND_COLOR } from './constants';

export const Container = styled.div`
  display: flex;
  box-sizing: border-box;
  overflow: hidden;
  height: 100vh;

  @media (max-width: 680px) {
    flex-direction: column;
    height: fit-content;
  }
`;

export const LeftSection = styled.section`
  max-width: 520px;
  background-image: url(/assets/images/background.svg);
  background-position: bottom;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: ${BACKGROUND_COLOR};
  padding: 36px 28px 0 52px;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;

  @media (max-width: 1200px) {
    max-width: 400px;
  }

  @media (max-width: 920px) {
    max-width: 300px;
    padding: 36px 28px 0 32px;
  }

  @media (max-width: 680px) {
    max-width: 100%;
    width: 100%;
    padding: 36px 28px 120px 32px;
  }
`;

export const Slogan = styled.h1`
  font-size: 32px;
  font-weight: bold;
  line-height: 1.33;
  margin: 40px 0 20px 0;
  letter-spacing: 0.5px;

  @media (max-width: 680px) {
    font-size: 22px;
  }
`;

export const ListHeader = styled.h5`
  font-size: 15px;
  text-transform: uppercase;
  color: ${(props) => props.theme.rainbow.palette.text.label};
  font-family: "Lato Bold", Arial, sans-serif;
  margin-bottom: 20px;

  @media (max-width: 680px) {
    font-size: 14px;
    margin-bottom: 12px;
  }
`;

export const H2 = styled.h2`
  margin: 61px 0 44px 0;
  font-size: 32px;
  font-weight: 500;
  line-height: 1.5;
`;

export const CheckIcon = styled(Check)`
  color: ${(props) => props.theme.rainbow.palette.brand.main};
  margin-right: 16px;
  width: 14px;
  height: 14px;
  flex-shrink: 0;
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

export const TextItem = styled.span`
  font-size: 14px;

  @media (max-width: 680px) {
    font-size: 13px;
  }
`;

export const Bold = styled.b`
  font-family: "Lato Bold", Arial, sans-serif;
  width: 100%;
`;

export const Form = styled(UniversalForm)`
  padding: 0 80px 28px 80px;
  margin: 0;
  background: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow-x: auto;

  @media (max-width: 1024px) {
    padding: 0 64px 28px 64px;
  }

  @media (max-width: 920px) {
    padding: 0 48px 28px 48px;
  }
`;

export const H1 = styled.h1`
  font-size: 26px;
  font-weight: bold;
  line-height: 1.33;
  margin: 32px 0 12px;

  @media (max-width: 680px) {
    font-size: 24px;
    text-align: center;
  }
`;

export const H3 = styled.h3`
  font-size: 22px;
  font-weight: bold;

  @media (max-width: 680px) {
    font-size: 20px;
    text-align: center;
  }
`;

export const H4 = styled.h4`
  font-size: 16px;
  color: ${(props) => props.theme.rainbow.palette.text.header};
  margin-bottom: 36px;

  @media (max-width: 680px) {
    font-size: 12px;
  }
`;

export const FormDescription = styled.h4`
  font-size: 16px;
  color: ${(props) => props.theme.rainbow.palette.text.header};
  margin-bottom: 12px;

  @media (max-width: 680px) {
    font-size: 12px;
    text-align: center;
  }
`;

export const FormContainer = styled.div`
  margin-top: 36px;
  margin-bottom: 20px;
`;

export const Link = styled.a`
  color: #000;
  text-decoration: underline;
  font-family: "Lato Bold", Arial, sans-serif;

  :hover,
  :active,
  :visited {
    color: #000;
  }
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: flex-end;
`;
