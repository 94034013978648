import axios from 'axios';

const url = process.env.REACT_APP_API_URL;
const username = process.env.REACT_APP_API_USER;
const password = process.env.REACT_APP_API_PASSWORD;

const axiosInstance = axios.create({
    baseURL: url,
    timeout: 20 * 1000,
    headers: {
        Authorization: `Basic ${btoa(`${username}:${password}`)}`,
        'Content-Type': 'application/json',
    },
});

export default axiosInstance;
