const getSubmitData = ({
    group, owner, priceId, isAuthenticated,
}) => {
    const { phoneNumber } = owner;
    const groupName = group.name.trim();
    if (isAuthenticated) {
        return {
            group: {
                country: group.country.isoCode.toUpperCase(),
                appearsOnStatementAs: groupName,
                name: groupName,
            },
            priceId,
        };
    }
    return ({
        group: {
            country: group.country.isoCode.toUpperCase(),
            appearsOnStatementAs: groupName,
            name: groupName,
        },
        owner: {
            ...owner,
            phoneNumber: `${phoneNumber.countryCode}${phoneNumber.phone}`,
        },
        priceId,
    });
};

export default getSubmitData;
