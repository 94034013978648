import Cookies from 'js-cookie';

const getEvertransitCookie = () => {
    const etCookie = Cookies.get(process.env.REACT_APP_ET_COOKIE_NAME);
    try {
        return etCookie && JSON.parse(etCookie);
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        return undefined;
    }
};

export default getEvertransitCookie;
