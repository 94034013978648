import React from 'react';
import PropTypes from 'prop-types';
import { Input, RenderIf } from 'react-rainbow-components';
import { CountryPicker } from '@rainbow-modules/forms';
import UserAccountCard from './userAccountCard';
import OwnerFields from './fields';
import { stripeCountries } from '../../constants';
import EmptyComponent from './emptyComponent';
import {
    InputField, Row, H3,
} from './styled';

export default function Form({ userCredentials, onAddNewAccount }) {
    const existUserData = !!userCredentials;

    return (
        <>
            <H3 className="rainbow-m-top_large">Fleet Information</H3>
            <Row>
                <InputField
                    id="fleet-name-input"
                    name="group.name"
                    component={Input}
                    label="Fleet Name"
                    placeholder="Enter the fleet name"
                    labelAlignment="left"
                    required
                />
                <InputField
                    name="group.country"
                    component={CountryPicker}
                    label="Fleet Country"
                    placeholder="Enter the country"
                    labelAlignment="left"
                    countries={stripeCountries}
                    required
                    emptyComponent={EmptyComponent}
                />
            </Row>
            <H3 className="rainbow-m-top_large">Owner Information</H3>
            <RenderIf isTrue={!existUserData}>
                <OwnerFields />
            </RenderIf>
            <RenderIf isTrue={existUserData}>
                <UserAccountCard
                    userCredentials={userCredentials}
                    onAddNewAccount={onAddNewAccount}
                />
            </RenderIf>
        </>
    );
}

Form.propTypes = {
    userCredentials: PropTypes.object,
    onAddNewAccount: PropTypes.func.isRequired,
};

Form.defaultProps = {
    userCredentials: undefined,
};
