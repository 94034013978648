import { PhoneNumberUtil } from 'google-libphonenumber';
import { isEmail } from '@rainbow-modules/validation';

const phoneUtil = PhoneNumberUtil.getInstance();

const isValidPhoneNumber = (phoneNumber, region = 'US') => {
    if (phoneNumber) {
        try {
            return phoneUtil.isValidNumber(
                phoneUtil.parseAndKeepRawInput(phoneNumber, region),
            );
        } catch (error) {
            return false;
        }
    }
    return false;
};

const validate = (values, isAuthenticated) => {
    const {
        group = {},
        owner = {},
        privacy,
    } = values;
    const errors = {
        group: {},
        owner: {},
    };
    if (!group.name) {
        errors.group.name = 'Looks like you forgot the fleet name';
    }
    if (!group.country) {
        errors.group.country = 'Looks like you forgot the country';
    }
    if (!isAuthenticated) {
        if (!owner.email) {
            errors.owner.email = 'Looks like you forgot the email';
        }
        if (owner.email && !isEmail(owner.email)) {
            errors.owner.email = 'Looks like the email entered is invalid';
        }
        if (!owner.firstName) {
            errors.owner.firstName = 'Looks like you forgot the first name';
        }
        if (!owner.lastName) {
            errors.owner.lastName = 'Looks like you forgot the last name';
        }
        if (!owner.phoneNumber || !owner.phoneNumber.phone) {
            errors.owner.phoneNumber = 'Looks like you forgot the phone number';
        }
        if (owner.phoneNumber && owner.phoneNumber.phone) {
            const { countryCode, phone: number, isoCode } = owner.phoneNumber;
            const phoneNumber = `${countryCode}${number}`;
            if (!isValidPhoneNumber(phoneNumber, isoCode)) {
                errors.owner.phoneNumber = 'Looks like the phone number entered is invalid';
            }
        }
        if (!owner.password) {
            errors.owner.password = 'Looks like you forgot the password';
        }
    }
    if (!privacy) {
        errors.privacy = "Looks like you forgot to agree to EverTransit's Privacy Policy";
    }
    return errors;
};

export default validate;
