import PropTypes from 'prop-types';
import { Modal } from 'react-rainbow-components';
import { useForm } from 'react-final-form';
import {
    ModalContainer, StyledCheckingModalIcon, Title, Description, Footer, StyledButton,
} from './styled';

const CheckingUserAccountModal = ({ isOpen, onRequestClose, onExistingAccount }) => {
    const form = useForm();

    const handleNewAccount = () => {
        form.restart();
        onRequestClose();
    };
    const handleExistingAccount = () => {
        onRequestClose();
        onExistingAccount();
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            size="medium"
        >
            <ModalContainer>
                <StyledCheckingModalIcon />
                <Title>It looks like you already have an account</Title>
                <Description>
                    We found that your email is registered as a dispatcher or driver in Evertransit.
                    To continue with your registration you can create a new account with another
                    email or login with your existing account.
                </Description>
                <Footer>
                    <StyledButton label="New account" variant="neutral" onClick={handleNewAccount} />
                    <StyledButton label="Existing account" variant="brand" onClick={handleExistingAccount} />
                </Footer>
            </ModalContainer>
        </Modal>
    );
};

CheckingUserAccountModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onRequestClose: PropTypes.func.isRequired,
    onExistingAccount: PropTypes.func.isRequired,
};

export default CheckingUserAccountModal;
