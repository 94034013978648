import React from 'react';
import EvertransitLogo from '../icons/EvertransitLogo';
import { TitleContainer, TitleText } from './styled';

const Title = () => (
    <TitleContainer>
        <EvertransitLogo />
        <TitleText>Sign in</TitleText>
    </TitleContainer>
);

export default Title;
