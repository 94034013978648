/* eslint-disable react/prop-types, camelcase, react/style-prop-object */
import { useMemo, useState, useEffect } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Button, Input, Spinner } from 'react-rainbow-components';
import { showAppMessage } from '@rainbow-modules/app';
import { Field } from '@rainbow-modules/forms';
import { useMutationFlow } from '@rainbow-modules/hooks';
import EvertransitLogo from './components/icons/EvertransitLogo';
import SignUpForm from './components/form';
import PricePicker from './components/pricePicker';
import validate from './validate';
import createSubscriptionSession from './services/createSubscriptionSession';
import getEvertransitToken from './helpers/getEvertransitToken';
import tryEvertransitTokenLogin from './helpers/tryEvertransitTokenLogin';
import CheckingUserAccountModal from './components/checkingUserAccountModal';
import LoginModal from './components/loginModal';
import ThankYou from './components/thankYou';
import firebase from './firebase';
import getProducts from './api/products';
import {
    Container,
    LeftSection,
    H1,
    Form,
    Link,
    Footer,
    H3,
    H4,
    Slogan,
    FormDescription,
} from './styled';
import getUserProfile from './services/getUserProfile';
import getSubmitData from './helpers/getSubmitData';

const getQuerySearch = (search) => {
    if (search) return search;
    const arr = window.location.href.split('?');
    if (arr.length > 1) {
        return `?${arr[1]}`;
    }
    return '';
};

const Main = () => {
    const { push, location } = useHistory();
    const { search } = location;
    const querySearch = getQuerySearch(search);
    const [showSpinner, setShowSpinner] = useState(true);
    const [userCredentials, setUserCredentials] = useState();
    const [isOpenCheckingModal, setIsOpenCheckingModal] = useState(false);
    const [products, setProducts] = useState([]);
    const isAuthenticated = !!userCredentials;
    useEffect(() => {
        try {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const productId = urlParams.get('productId');
            getProducts(productId).then((response) => {
                if (response.status === 200) {
                    const sortedProducts = (productId == null)
                        ? response.data.sort(
                            (a, b) => a.prices.recurringFee - b.prices.recurringFee,
                        )
                        : [response.data];
                    setProducts(sortedProducts);
                }
            });
        } catch (error) {
            // eslint-disable-next-line
            console.log(error);
        }
    }, []);
    const initialValues = useMemo(
        () => ({
            group: {
                country: {
                    isoCode: 'us',
                },
            },
            priceId: products && products[0] && products[0].defaultPrice,
        }),
        [products],
    );
    const { mutate } = useMutationFlow({
        mutation: createSubscriptionSession,
        onSuccess: async (response) => {
            if (response && response.data) {
                const stripe = await loadStripe(
                    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
                );
                if (window.Cypress) return;
                stripe.redirectToCheckout({ sessionId: response.data });
            }
        },
        successMessage: null,
        errorMessage: null,
        onError: (error) => {
            if (error.code === 'already-exists') {
                setIsOpenCheckingModal(true);
            } else {
                showAppMessage({
                    timeout: null,
                    message: error.message,
                    variant: 'error',
                });
            }
        },
        type: {
            error: 'message',
        },
    });

    const handleSubmit = (values) => {
        const { owner = {}, group = {}, priceId } = values;
        const submitData = getSubmitData({
            group,
            owner,
            priceId,
            isAuthenticated,
        });
        return mutate({
            ...submitData,
            querySearch,
        });
    };

    useEffect(() => {
        (async () => {
            const evertransitToken = getEvertransitToken();
            await tryEvertransitTokenLogin(evertransitToken);
            const user = await getUserProfile();
            setUserCredentials(user.data);
            setShowSpinner(false);
        })();
    }, []);

    if (showSpinner) {
        return <Spinner />;
    }

    const handleOnAddNewAccount = async () => {
        setShowSpinner(true);
        await firebase.auth().signOut();
        setUserCredentials();
        setShowSpinner(false);
    };

    return (
        <Container>
            <LeftSection>
                <EvertransitLogo />
                <div>
                    <Slogan>Fleet Management made effortless</Slogan>
                    <H4>
                        Let EverTransit manage the complexities of your transportation
                        business, so you can spend your time on more strategic initiatives -
                        like growing your business.
                    </H4>
                </div>
                <ul />
            </LeftSection>
            <Form
                id="signup-form"
                onSubmit={handleSubmit}
                initialValues={initialValues}
                validate={(values) => validate(values, isAuthenticated)}
            >
                <H1>Create your EverTransit account</H1>
                <FormDescription>Simple setup.</FormDescription>
                <SignUpForm
                    userCredentials={userCredentials}
                    onAddNewAccount={handleOnAddNewAccount}
                />
                <H3 className="rainbow-m-top_large">Your plan</H3>
                <Field
                    id="price-picker"
                    name="priceId"
                    component={PricePicker}
                    options={products}
                />
                <Field
                    id="privacy-checkbox"
                    name="privacy"
                    component={Input}
                    label={(
                        <span>
                            I agree to EverTransit&apos;s
                            {' '}
                            <Link
                                href="https://evertransit.com/privacy"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Privacy Policy
                            </Link>
                            {' '}
                            &
                            {' '}
                            <Link
                                href="https://www.evertransit.com/terms-of-service"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Terms of Service
                            </Link>
                        </span>
                    )}
                    labelAlignment="left"
                    required
                    type="checkbox"
                />
                <Footer>
                    <Button
                        id="submit-button"
                        label="Sign Up"
                        type="submit"
                        variant="brand"
                        form="signup-form"
                    />
                </Footer>
                <CheckingUserAccountModal
                    isOpen={isOpenCheckingModal}
                    onRequestClose={() => setIsOpenCheckingModal(false)}
                    onExistingAccount={() => push('/login')}
                />
                <LoginModal onLoginUser={setUserCredentials} />
            </Form>
        </Container>
    );
};

const App = () => (
    <Switch>
        <Route exact path="/thank-you/:plan" component={ThankYou} />
        <Route path={['/', '/login']}>
            <Main />
        </Route>
    </Switch>
);

export default App;
