import React from 'react';
import PropTypes from 'prop-types';
import {
    MessageContainer,
    StyledSearchIcon,
    MessageDescription,
    MessageHighLight,
    StyledAnchor,
} from './styled';

const EmptyComponent = ({ searchValue }) => (
    <MessageContainer>
        <StyledSearchIcon />
        <MessageDescription>
            EverTransit&apos;s integrated payment processor does not support your country.
            {' '}
            <StyledAnchor
                href="mailto:support@evertransit.com?subject=Country%20Not%20Available"
                target="_blank"
                rel="noopener noreferrer"
            >
                Contact us
            </StyledAnchor>
            {' '}
            for more information.
        </MessageDescription>
        <MessageHighLight>
            “
            {searchValue}
            ”
        </MessageHighLight>
    </MessageContainer>
);

EmptyComponent.propTypes = {
    searchValue: PropTypes.string.isRequired,
};

export default EmptyComponent;
