import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { useField } from 'react-final-form';
import { Modal } from 'react-rainbow-components';
import { UniversalForm } from '@rainbow-modules/forms';
import { useMutationFlow } from '@rainbow-modules/hooks';
import Fields from './fields';
import {
    StyledButton,
    FormContainer,
    StyledAnchor,
    AnchorContainer,
} from './styled';
import Title from './title';
import makeRequest from '../../services/makeRequest';
import signInWithCustomToken from '../../services/signInWithCustomToken';
import setFirebaseCookieToken from '../../services/setFirebaseCookieToken';
import getUserProfile from '../../services/getUserProfile';

const url = `${process.env.REACT_APP_BASE_URL}/auth`;

const LoginModal = ({ onLoginUser }) => {
    const { push } = useHistory();
    const { input: emailInput } = useField('owner.email');
    const location = useLocation();
    const isOpenLoginModal = location.pathname === '/login';

    const { mutate } = useMutationFlow({
        mutation: async (config) => {
            const response = await makeRequest({
                url,
                method: 'POST',
                data: { ...config },
            });
            const { token } = await response.json();
            if (token) {
                const { user } = await signInWithCustomToken(token);
                if (user) {
                    setFirebaseCookieToken(token);
                    const userCredentials = await getUserProfile();
                    onLoginUser(userCredentials.data);
                }
            } else {
                throw new Error('Invalid Authentication Token');
            }
        },
        errorMessage: () => 'Sorry, the email or password is incorrect.',
        successMessage: null,
        onSuccess: () => push('/'),
        onError: () => push('/'),
        type: {
            success: 'notification',
        },
    });

    const handleSubmit = (values) => mutate(values);

    const baseUrl = encodeURIComponent(window.location.href);
    const forgotPasswordUrl = `${process.env.REACT_APP_DISPATCH_URL}/#/forgot?redirect_URL=${baseUrl}`;

    return (
        <Modal
            isOpen={isOpenLoginModal}
            onRequestClose={() => push('/')}
            title={<Title />}
        >
            <FormContainer>
                <UniversalForm id="login-form" onSubmit={handleSubmit}>
                    <Fields emailInitialValue={emailInput.value} />
                </UniversalForm>
                <StyledButton
                    label="Sign in"
                    type="submit"
                    form="login-form"
                    variant="brand"
                />
            </FormContainer>
            <AnchorContainer>
                <StyledAnchor href={forgotPasswordUrl}>
                    Forgot Password
                </StyledAnchor>
            </AnchorContainer>
        </Modal>
    );
};

LoginModal.propTypes = {
    onLoginUser: PropTypes.func.isRequired,
};

export default LoginModal;
