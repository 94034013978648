/* eslint-disable react/prop-types, camelcase, react/style-prop-object */
import axiosInstance from './apiInstance';

const getProducts = async (productId) => {
    try {
        const resp = (productId == null)
            ? await axiosInstance.get('/products')
            : await axiosInstance.get(`/products/${productId}`);
        return resp;
    } catch (error) {
        return error.response;
    }
};

export default getProducts;
