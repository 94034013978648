import authWithEtToken from '../services/authWithEtToken';
import signInWithCustomToken from '../services/signInWithCustomToken';
import setFirebaseCookieToken from '../services/setFirebaseCookieToken';

const tryEvertransitTokenLogin = async (evertransitToken) => {
    if (evertransitToken) {
        try {
            const customToken = await authWithEtToken(evertransitToken);
            if (customToken) {
                const userCredentials = await signInWithCustomToken(customToken);
                if (userCredentials.user) {
                    setFirebaseCookieToken(customToken);
                    return userCredentials;
                }
            }
        } catch (error) {
            return undefined;
        }
    }
    return undefined;
};

export default tryEvertransitTokenLogin;
