/* stylelint-disable font-family-no-missing-generic-family-keyword */
import styled from 'styled-components';
import EvertransitLogo from '../icons/EvertransitLogo';
import { BACKGROUND_COLOR } from '../../constants';

export const Background = styled.section`
    background-image: url(/assets/images/thank-you-background.svg);
    background-position: bottom;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: ${BACKGROUND_COLOR};
    padding: 52px;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
`;

export const Logo = styled(EvertransitLogo)`
    position: absolute;
    top: 52px;
    left: 52px;
`;

export const Title = styled.h1`
    font-size: 28px;
    font-weight: bold;
    line-height: 1.71;
    margin-bottom: 20px;
    letter-spacing: 0.64px;
    text-align: center;
    color: #081832;

    @media (max-width: 680px) {
        font-size: 22px;
    }
`;

export const Description = styled.span`
    font-size: 18px;
    font-weight: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: center;
    color: #6b6d77;
    max-width: 580px;
    margin-bottom: 24px;
`;

export const Bold = styled.span`
    font-weight: 800;
    font-family: 'Lato Bold';
`;

export const Arrow = styled.div`
    border-right: 2px solid;
    border-bottom: 2px solid;
    height: 8px;
    width: 8px;
    transform: rotate(-45deg);
    margin-left: 12px;
`;
