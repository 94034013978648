/* eslint-disable react/prop-types, camelcase, react/style-prop-object */
import { UniversalPickerOption } from '@rainbow-modules/forms';
import { RenderIf } from 'react-rainbow-components';
import { FormattedNumber } from 'react-intl';
import { useState } from 'react';
import useReduxForm from 'react-rainbow-components/libs/hooks/useReduxForm';
import {
    CheckIcon, ListItem, TextItem, Bold,
} from '../../styled';
import {
    Picker,
    StyledOption,
    Label,
    CheckMarkIcon,
    FlatAmount,
    SecondaryText,
    ShowMore,
} from './styled';

const Item = (props) => {
    const { children, isSelected } = props;
    return (
        <StyledOption isSelected={isSelected}>
            <RenderIf isTrue={isSelected}>
                <CheckMarkIcon />
            </RenderIf>
            {children}
        </StyledOption>
    );
};

// eslint-disable-next-line react/jsx-props-no-spreading

// Start Region Api-Products

const FeaturesMap = ({ features, setupFee }) => {
    const [showAll, setShowAll] = useState(false);
    const displayedItems = showAll ? features : features.slice(0, 4);
    const setupFeeMessage = `Setup Fee - $${setupFee}`;
    return (
        <div>
            {displayedItems.map((item) => (
                <ListItem>
                    <CheckIcon />
                    <TextItem>
                        <Bold>{item}</Bold>
                    </TextItem>
                </ListItem>
            ))}

            {features.length > 5 && (
                <div>
                    {showAll ? (
                        <>
                            <ListItem>
                                <CheckIcon />
                                <TextItem>
                                    <Bold>
                                        {' '}
                                        {setupFeeMessage}
                                    </Bold>
                                </TextItem>
                            </ListItem>
                            <ShowMore onClick={() => setShowAll(false)}>
                                Show less...
                            </ShowMore>
                        </>
                    ) : (
                        <ShowMore onClick={() => setShowAll(true)}>Show more...</ShowMore>
                    )}
                </div>
            )}
        </div>
    );
};
const TiersApi = ({ id, data }) => {
    const { recurringFee, overage, currency } = data;

    return (
        <span key={id}>
            <FlatAmount>
                <FormattedNumber
                    value={recurringFee}
                    style="currency"
                    currency={currency}
                    maximumFractionDigits={0}
                />
                <SecondaryText> / Month</SecondaryText>
            </FlatAmount>
            <ListItem>
                <CheckIcon />
                <TextItem>
                    <Bold>{`${overage.limit} rides `}</Bold>
                    / month
                </TextItem>
            </ListItem>

            <ListItem>
                <CheckIcon />
                <TextItem>
                    <Bold>
                        Then
                        {' '}
                        <FormattedNumber
                            value={overage.fee}
                            style="currency"
                            currency="USD"
                            maximumFractionDigits={10}
                        />
                    </Bold>
                    / ride
                </TextItem>
            </ListItem>
        </span>
    );
};

// eslint-disable-next-line react/jsx-props-no-spreading
const OptionsFromApi = ({ data = [] }) => data.map((item) => <OptionApi key={item.id} {...item} />);

// eslint-disable-next-line react/jsx-props-no-spreading
const OptionApi = (props) => {
    const {
        name = '', prices, features, defaultPrice,
    } = props;
    return (
        <UniversalPickerOption name={defaultPrice} component={Item}>
            <Label>{name}</Label>
            <TiersApi id={defaultPrice} data={prices} />
            <FeaturesMap features={features} setupFee={prices.setupFee} />
        </UniversalPickerOption>
    );
};

// End Region
const PricePicker = (props) => {
    const {
        value, onChange, name, options, id,
    } = useReduxForm(props);
    return (
        <>
            <Picker id={id} value={value} onChange={onChange} name={name}>
                {options.length === 0 ? (
                    <p>No Plans available.Please contact administrator.</p>
                ) : (
                    <OptionsFromApi data={options} />
                )}
            </Picker>
        </>
    );
};

export default PricePicker;
