import styled from 'styled-components';
import { UniversalPicker } from '@rainbow-modules/forms';
import { CheckCircleFilled } from '@rainbow-modules/icons';

export const Picker = styled(UniversalPicker)`
  margin-top: 36px;
  margin-bottom: 32px;

  > div {
    justify-content: flex-start;
  }
`;

export const StyledOption = styled.div`
  display: flex;
  flex-direction: column;
  width: 220px;
  min-height: 280px;
  margin-right: 12px;
  margin-bottom: 12px;
  padding: 24px 20px 24px 20px;
  border-radius: 12px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
  position: relative;

  :hover {
    cursor: pointer;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  }

  ${(props) => props.isSelected
    && `
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
    `}
`;

export const Label = styled.span`
  font-size: 18px;
  text-transform: capitalize;
  margin-bottom: 12px;
`;

export const CheckMarkIcon = styled(CheckCircleFilled)`
  position: absolute;
  top: -8px;
  right: -8px;
  width: 28px;
  height: 28px;
  color: ${(props) => props.theme.rainbow.palette.brand.main};
`;

export const FlatAmount = styled.span`
  font-size: 30px;
  font-weight: 800;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

export const SecondaryText = styled.span`
  font-size: 14px;
  color: ${(props) => props.theme.rainbow.palette.text.header};
  margin-left: 8px;
`;

export const ShowMore = styled.button`
  border: none;
  background-color: transparent;
  outline: none;
  border: none;
  color: green;
  float: right;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  margin-top: 4px;
`;
