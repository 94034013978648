import React from 'react';
import PropTypes from 'prop-types';

const CheckingModalIcon = ({ title, className, style }) => (
    <svg width="192px" height="122px" viewBox="0 0 192 122" version="1.1" xmlns="http://www.w3.org/2000/svg" className={className} style={style}>
        <title>{title}</title>
        <defs>
            <polygon id="path-1" points="0.3469 0.632222686 15.7569 0.632222686 15.7569 26.861 0.3469 26.861" />
            <polygon id="path-3" points="0.1624 0.3022 11.2954 0.3022 11.2954 9.861 0.1624 9.861" />
            <polygon id="path-5" points="0.498 76.861 56.307 76.861 56.307 0.673131606 0.498 0.673131606" />
        </defs>
        <g id="self-onboarding" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="signup-for-free/-error" transform="translate(-596.000000, -195.000000)">
                <g id="image" transform="translate(596.000000, 195.081000)">
                    <path d="M179.1806,120.3367 L13.1566,120.3367 C6.1586,120.3367 0.4846,114.6637 0.4846,107.6647 L0.4846,13.5237 C0.4846,6.5257 6.1586,0.8517 13.1566,0.8517 L179.1806,0.8517 C186.1786,0.8517 191.8526,6.5247 191.8526,13.5237 L191.8526,107.6647 C191.8526,114.6637 186.1786,120.3367 179.1806,120.3367" id="Fill-15" fill="#F7F8F9" />
                    <path d="M178.6747,0.8519 L13.6627,0.8519 C6.3847,0.8519 0.4847,6.7519 0.4847,14.0289 L0.4847,25.2829 L191.8517,25.2829 L191.8517,14.0289 C191.8517,6.7519 185.9527,0.8519 178.6747,0.8519" id="Fill-18" fill="#C5CFD5" />
                    <polygon id="Fill-20" fill="#FFFFFF" points="16.602 14.738 172.229 14.738 172.229 6.887 16.602 6.887" />
                    <polygon id="Fill-22" fill="#FFFFFF" points="16.602 19.682 172.229 19.682 172.229 17.27 16.602 17.27" />
                    <polygon id="Fill-23" fill="#C5CFD5" points="86.93 43.031 116.659 43.031 116.659 37.098 86.93 37.098" />
                    <polygon id="Fill-24" fill="#C5CFD5" points="86.93 50.906 146.908 50.906 146.908 45.957 86.93 45.957" />
                    <polygon id="Fill-25" fill="#C5CFD5" points="86.931 61.971 129.85 61.971 129.85 56.038 86.931 56.038" />
                    <polygon id="Fill-26" fill="#C5CFD5" points="86.931 69.846 147.124 69.846 147.124 64.897 86.931 64.897" />
                    <polygon id="Fill-27" fill="#C5CFD5" points="86.931 80.911 146.911 80.911 146.911 74.978 86.931 74.978" />
                    <polygon id="Fill-28" fill="#C5CFD5" points="86.93 88.786 116.315 88.786 116.315 83.837 86.93 83.837" />
                    <polygon id="Fill-29" fill="#C5CFD5" points="86.93 99.851 116.659 99.851 116.659 93.918 86.93 93.918" />
                    <polygon id="Fill-30" fill="#C5CFD5" points="86.93 107.726 162.908 107.726 162.908 102.777 86.93 102.777" />
                    <polygon id="Fill-31" fill="#DEE6DA" points="15.498 110.861 71.307 110.861 71.307 34.077 15.498 34.077" />
                    <g id="Group-56" transform="translate(15.000000, 34.000000)">
                        <g id="Group-35" transform="translate(21.000000, 50.000000)">
                            <mask id="mask-2" fill="white">
                                <use xlinkHref="#path-1" />
                            </mask>
                            <g id="Clip-34" />
                            <path d="M0.3469,3.8069 L0.9119,30.8429 L8.1059,36.5519 L15.7569,30.3859 L15.5289,4.4639 C15.5289,4.4639 12.5719,-3.7011 0.3469,3.8069" id="Fill-33" fill="#FFFFFF" mask="url(#mask-2)" />
                        </g>
                        <polygon id="Fill-36" fill="#E08663" points="29.6114 67.751 27.8464 67.751 26.0044 60.904 31.4524 60.904" />
                        <g id="Group-40" transform="translate(23.000000, 67.000000)">
                            <mask id="mask-4" fill="white">
                                <use xlinkHref="#path-3" />
                            </mask>
                            <g id="Clip-39" />
                            <polygon id="Fill-38" fill="#E08663" mask="url(#mask-4)" points="11.2954 20.0562 0.1624 20.0562 4.8804 0.3022 6.5774 0.3022" />
                        </g>
                        <mask id="mask-6" fill="white">
                            <use xlinkHref="#path-5" />
                        </mask>
                        <g id="Clip-46" />
                        <polygon id="Fill-45" fill="#ECC19C" mask="url(#mask-6)" points="21.386 60.805 36.51 60.805 36.51 42.639 21.386 42.639" />
                        <path d="M21.3855,50.796 C23.7065,51.795 26.2605,52.354 28.9475,52.354 C31.6335,52.354 34.1885,51.795 36.5095,50.796 L36.5095,42.64 L21.3855,42.64 L21.3855,50.796 Z" id="Fill-47" fill="#DEB592" mask="url(#mask-6)" />
                        <path d="M25.8766,25.2105 C25.8766,25.2105 28.1936,25.6195 27.9206,29.5715 C27.6486,33.5235 26.4216,37.4755 28.0566,37.6115 C29.6926,37.7475 30.3736,36.7935 30.3736,36.7935 C30.3736,36.7935 30.2376,39.7915 27.5116,38.8375 C24.7866,37.8845 26.2856,32.9785 26.8306,30.6615 C27.3756,28.3445 25.8766,25.2105 25.8766,25.2105" id="Fill-51" fill="#FEBDA3" mask="url(#mask-6)" />
                        <polygon id="Fill-52" fill="#E0E0E0" mask="url(#mask-6)" points="21.3611 53.8069 29.0201 61.0929 21.1741 65.7629" />
                        <polygon id="Fill-53" fill="#E0E0E0" mask="url(#mask-6)" points="36.5338 53.8069 28.8738 61.0929 36.7208 65.7629" />
                        <path d="M21.2837,53.3674 C21.2907,53.8064 29.2497,87.6714 29.2497,87.6714 L-3.2173,87.6714 L-3.2173,61.7184 L21.2837,53.3674 Z" id="Fill-54" fill="#45454F" mask="url(#mask-6)" />
                        <path d="M36.6112,53.3674 C36.6042,53.8064 28.6452,87.6714 28.6452,87.6714 L61.1122,87.6714 L61.1122,61.7184 L36.6112,53.3674 Z" id="Fill-55" fill="#45454F" mask="url(#mask-6)" />
                    </g>
                    <g id="Group-28" transform="translate(23.500000, 39.509590)" fillRule="nonzero">
                        <path d="M4.24321276,9.92544757 L4.24321276,25.9031429 C4.24321276,36.7111011 13.731135,45.4724253 19.8561171,45.4724253 C25.9803532,45.4724253 35.4690214,36.7111011 35.4690214,25.9031429 L35.4690214,9.92544757 L4.24321276,9.92544757 Z" id="Path" fill="#ECC19C" />
                        <path d="M32.9255555,6.98473999 C29.5227048,2.72399638 24.3570707,-5.68434189e-14 18.5789757,-5.68434189e-14 C8.31372795,-5.68434189e-14 6.03961325e-14,8.56848484 6.03961325e-14,19.1351141 C6.03961325e-14,24.7434955 2.33757159,29.7864136 6.07015159,33.2836326 C5.10856557,30.8703426 4.57145003,28.1653691 4.57145003,25.3089588 C4.57145003,20.7184859 5.96645845,16.5144378 8.28538019,13.2813007 C10.7456678,14.3749276 14.7330046,14.7706773 19.1063933,14.1675413 C23.4424823,13.5737303 27.1373896,12.1410492 29.2205773,10.4349613 C33.0195508,13.7904415 35.4891633,19.2011345 35.4891633,25.3089588 C35.4891633,28.4384028 34.8479816,31.3794834 33.6980321,33.9621139 C37.3645917,32.4443895 40.0606133,27.0340695 40.0606133,20.5957699 C40.0606133,13.7053982 36.968842,8.02167138 32.9255555,6.98473999 Z" id="Path" fill="#494846" />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

CheckingModalIcon.propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
};

CheckingModalIcon.defaultProps = {
    title: undefined,
    className: undefined,
    style: undefined,
};

export default CheckingModalIcon;
